import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyACaMaFErC8tQYV1zzFFkrZmdqBAbJynTI",
  authDomain: "app-denegocios-cl.firebaseapp.com",
  projectId: "app-denegocios-cl",
  storageBucket: "app-denegocios-cl.firebasestorage.app",
  messagingSenderId: "503308251899",
  appId: "1:503308251899:web:5889d6e6a765dae61b365e",
  measurementId: "G-3Z4GF4HVE3"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
