import React, { useState, useEffect } from "react";
import getState from "./Flux.js";

export const Context = React.createContext(null);

const injectContext = (PassedComponent) => {
	const StoreWrapper = (props) => {
		//this will be passed as the contenxt value
		const [state, setState] = useState(
			getState({
				getStore: () => state.store,
				getActions: () => state.actions,
				setStore: (updatedStore) =>
					setState({
						store: Object.assign(state.store, updatedStore),
						actions: { ...state.actions },
					}),
			})
		);

		useEffect(() => {
			// Verificar la caducidad del token y usuario
			const checkSessionValidity = () => {
				const expirationTime = localStorage.getItem('sessionExpiration');
				const currentTime = new Date().getTime();

				// Si no hay tiempo de expiración o el tiempo actual es mayor al de expiración
				if (!expirationTime || currentTime > parseInt(expirationTime)) {
					// Limpiar localStorage
					localStorage.removeItem('usuarioActual');
					localStorage.removeItem('token');
					localStorage.removeItem('sessionExpiration');
					
					// Limpiar store usando setState directamente
					setState(state => ({
						...state,
						store: {
							...state.store,
							usuarioActual: null,
							token: null
						}
					}));
					
					return false;
				}
				return true;
			};

			// Si la sesión es válida, cargar los datos
			if (checkSessionValidity()) {
				setState(state => ({
					...state,
					store: {
						...state.store,
						usuarioActual: JSON.parse(localStorage.getItem("usuarioActual")),
						token: localStorage.getItem("token")
					}
				}));
			}

			// Verificar la sesión cada minuto
			const intervalId = setInterval(checkSessionValidity, 60000);

			// Cleanup del intervalo
			return () => clearInterval(intervalId);
		}, []);

		// The initial value for the context is not null anymore, but the current state of this component,
		// the context will now have a getStore, getActions and setStore functions available, because they were declared
		// on the state of this component
		return (
			<Context.Provider value={state}>
				<PassedComponent {...props} />
			</Context.Provider>
		);
	};
	return StoreWrapper;
};

export default injectContext;
