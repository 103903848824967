import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../store/AppContext";

export const EliminarUsuario = ({
	setEliminar,
	usuarioCliqueado,
	setUsuarioCliqueado,
	setAnimarModal,
	animarModal,
}) => {
	const { store, actions } = useContext(Context);

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				top: 0,
				behavior: 'smooth'
			});
			setAnimarModal(true);
		}, 100);

		return () => {
			setAnimarModal(false);
		};
	}, []);

	const HandlerCerrar = () => {
		setAnimarModal(false);

		setTimeout(() => {
			setEliminar(false);
			setUsuarioCliqueado(null);
		}, 500);
	};

	const HandlerEliminar = (event) => {
		actions.borrarUsuario(store.usuario.id);

		setTimeout(() => {
			actions.getUsuarios();
		}, 1000);
		setEliminar(false);
	};

	return (
		<div className='modal'>
			<div className='cerrar-modal'>
				<button
					className='close-button'
					aria-label='Dismiss alert'
					type='button'
					data-close
					onClick={(e) => HandlerCerrar(e)}
				>
					<span aria-hidden='true'>&times;</span>
				</button>
			</div>
			<div className={`formulario ${animarModal ? "animar" : "cerrar"}`}>
				<div
					style={{ display: "grid", placeContent: "center", color: "white" }}
				>
					<h2>
						Hola, vas a Eliminar a{" "}
						<strong>
							{usuarioCliqueado.nombre} {usuarioCliqueado.apellido}
						</strong>{" "}
					</h2>
					<h3>Esta acción será irreversible</h3>
					<div className='button-group align-right'>
						<a className='button alert' onClick={(e) => HandlerEliminar(e)}>
							Eliminar Usuario
						</a>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EliminarUsuario;
