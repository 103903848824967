import { jsPDF } from "jspdf";
import { Hora } from "../Fecha";
import logopequeno from "../../../img/logopequeno.png";
import direccionfinal from "../../../img/direccionfinal.png";

export const ContratoContabilidadPersonaNatural = (datos) => {
	let { nombre, rut, razon, rutEmpresa, fecha, nacionalidad, planContratado } =
		datos;
	if (fecha === "hoy") {
		fecha = Hora();
	}
	const doc = new jsPDF();
	doc.addImage(logopequeno, "PNG", 3, 3, 25, 10);
	doc.setFont("arial", "bold");
	doc.setFontSize(20);
	doc.text("CONTRATO PRESTACIÓN SERVICIOS DE", 34, 20);
	doc.text("CONTABILIDAD PERSONA NATURAL.", 40, 30);
	doc.setFontSize(12);
	doc.setFont("arial", "normal");
	var text1 = `En Santiago de Chile, a ${fecha}, entre Asesorias e Inversiones DN SpA, Rut 77.526.796-8, domiciliado en Ahumada 254, oficina 806, Santiago Centro, en lo sucesivo los “PRESTADORES” y don/doña ${nombre}, Rut: ${rut}, de nacionalidad ${nacionalidad}, en lo sucesivo “EL CLIENTE”, se ha convenido el siguiente contrato de prestación de servicios.`;
	var splitTitle1 = doc.splitTextToSize(text1, 190);
	doc.text(10, 42, splitTitle1);
	doc.setFont("arial", "bold");
	doc.text("PRIMERO", 10, 72);
	doc.line(10, 73, 30, 73);
	doc.setFont("arial", "normal");
	var text2 = `El cliente contrata a Asesorias e Inversiones DN SpA, para que preste servicios en calidad de CONTADOR para realizar las labores que se indicarán para el ${planContratado} :`;
	var splitTitle2 = doc.splitTextToSize(text2, 190);
	doc.text(10, 78, splitTitle2);
	doc.text(`El ${planContratado} incluye lo siguiente:`, 10, 91);

	/* Bloque gris */
	doc.setDrawColor(0);
	doc.setFillColor(235, 235, 235);
	doc.rect(10, 96, 190, 120, "F");
	doc.setFontSize(30);

	/* Bloque Negro */
	doc.setDrawColor(0);
	doc.setFillColor(105, 105, 105);
	doc.rect(10, 96, 100, 20, "F");

	/* Texto Caja Negra */
	doc.setTextColor(255, 255, 255);
	doc.setFontSize(30);
	doc.setFont("arial", "normal");
	doc.text(15, 110, `${planContratado}`);

	if (planContratado == "Plan Básico") {
		/* Texto Gris */
		doc.setTextColor(0, 0, 0);
		doc.setFont("arial", "bold");
		doc.text(125, 160, "$35.000");
		doc.setFont("arial", "normal");
		doc.setFontSize(16);
		doc.text(125, 168, "Valor Mensual");
		doc.text(125, 174, "Incluye IVA");
		doc.setFontSize(12);

		/* Texto dentro de Caja gris */
		doc.text("-Declaracion de Formulario 29 (F29)", 15, 124);
		doc.text("-Revisión de Libro de Compras y de Ventas (Voucher)", 15, 130);
		doc.text("-Correos informativos y recordatorios", 15, 136);
		doc.text("-Asesorias", 15, 142);
	} else if (planContratado == "Plan Emprende") {
		/* Texto Gris */
		doc.setTextColor(0, 0, 0);
		doc.setFont("arial", "bold");
		doc.text(125, 160, "$60.000");
		doc.setFont("arial", "normal");
		doc.setFontSize(16);
		doc.text(125, 168, "Valor Mensual");
		doc.text(125, 174, "Incluye IVA");
		doc.setTextColor(0, 0, 0);
		doc.setFontSize(12);

		/* Texto dentro de Caja gris */
		doc.text("-Declaracion de Formulario 29 (F29)", 15, 124);
		doc.text("-Revisión de Libro de Compras y de Ventas (Voucher)", 15, 130);
		doc.text("-Correos informativos y recordatorios", 15, 136);
		doc.text("-Carpeta Tributaria", 15, 142);
		doc.text("-Generación de E-Rut", 15, 148);
		doc.text("-Declaración de Ingresos (DIN)", 15, 154);
		doc.text("-Envío Libros Mensuales (compra y venta)", 15, 160);
		doc.text("-Rectificación sin cobros extra", 15, 166);
		doc.text("-Seguimiento de las Declaraciones Mensuales", 15, 172);
		doc.text("-Asesorias", 15, 178);
	} else if (planContratado == "Plan Pyme") {
		/* Texto Gris */
		doc.setTextColor(0, 0, 0);
		doc.setFont("arial", "bold");
		doc.text(125, 160, "$120.000");
		doc.setFont("arial", "normal");
		doc.setFontSize(16);
		doc.text(125, 168, "Valor Mensual");
		doc.text(125, 174, "Incluye IVA");
		doc.setTextColor(0, 0, 0);
		doc.setFontSize(12);

		/* Texto dentro de Caja gris */
		doc.text("-Declaracion de Formulario 29 (F29)", 15, 124);
		doc.text("-Revisión de Libro de Compras y de Ventas (Voucher)", 15, 130);
		doc.text("-Correos informativos y recordatorios", 15, 136);
		doc.text("-Carpeta Tributaria", 15, 142);
		doc.text("-Generación de E-Rut", 15, 148);
		doc.text("-Declaración de Ingresos (DIN)", 15, 154);
		doc.text("-Envío Libros Mensuales (compra y venta)", 15, 160);
		doc.text("-Contabilidad Mensual", 15, 166);
		doc.text("-Rectificación sin cobros extra", 15, 172);
		doc.text("-Seguimiento de las Declaraciones Mensuales", 15, 178);
		doc.text("-Registro por Caja", 15, 184);
		doc.text("-Registro de Activo Fijo", 15, 190);
		doc.text("-Conciliación Bancaria", 15, 196);
		doc.text("-Acuse de Recibo (último día habil)", 15, 202);
		doc.text("-Asesorias", 15, 208);
	} else {
		alert("Error");
	}

	var text3 =
		"El valor indicado, corresponde al monto mensual que EL CLIENTE deberá cancelar todos los meses por la prestación de servicios. El valor indicado, será reajustado anualmente en función del IPC. El valor indicado, ya incluye IVA.";
	var splitTitle3 = doc.splitTextToSize(text3, 190);
	doc.text(10, 224, splitTitle3);
	var text4 =
		"El valor mensual anteriormente indicado, no incluye los Procesos de Renta (Declaraciones Juradas, F22 o Formulario de Declaración de Renta y otros relacionados). Estos procesos de Renta serán de cargo adicional y serán avizados a su debido momento. Más información de este punto puede ser visto en el articulo CUARTO.";
	var splitTitle4 = doc.splitTextToSize(text4, 190);
	doc.text(10, 242, splitTitle4);

	var text5 =
		"El cliente podrá, previa conversación con el equipo de contabilidad de DeNegocios.cl, realizar un cambio de plan acorde a sus necesidades y requerimientos. Podrá encontrar un listado de precios y servicios en nuestra página web denegocios.cl/servicio-de-contabilidad/ para más detalles.";
	var splitTitle5 = doc.splitTextToSize(text5, 190);
	doc.text(10, 260, splitTitle5);

	doc.addImage(direccionfinal, "PNG", 80, 280, 45, 10);

	/* Second Page is from here */

	doc.addPage();
	doc.addImage(logopequeno, "PNG", 3, 3, 25, 10);
	doc.setFont("arial", "normal");
	doc.text(
		"Adicionalmente y complementariamente el cliente podrá contratar los siguientes servicios:",
		10,
		22
	);
	doc.setFont("arial", "bold");
	doc.text("A) Servicio de Remuneraciones", 30, 30);
	doc.line(30, 31, 83, 31);
	doc.setFont("arial", "normal");
	doc.text(
		"Este servicio es complementario a los planes de servicios mensuales.",
		30,
		36
	);
	doc.setFont("arial", "bold");
	doc.text("Incluye:", 30, 42);
	doc.setFont("arial", "normal");
	doc.text(
		"1) Elaboración de contratos de trabajo con sus actualizaciones y anexos.",
		30,
		48
	);
	doc.text("2) Cálculo de liquidaciones de sueldo", 30, 54);
	doc.text("3) Cálculo de imposiciones y carga en PREVIRED", 30, 60);
	doc.text(
		"4) Control, cálculo y emisión de comprobantes de vacaciones.",
		30,
		66
	);
	doc.text(
		"5) Cálculo y elaboración de finiquitos (En caso de ser necesario)",
		30,
		72
	);
	doc.text("El valor de este servicio es de: ", 30, 81);
	doc.setFont("arial", "bold");
	doc.text("$15.000 ", 83, 81);
	doc.setFont("arial", "normal");
	doc.text("por trabajador mensualmente. ", 98, 81);
	var text1 =
		"La contratación de este servicio es opcional, y aplica si el cliente desea que llevemos las remuneraciones de trabajadores o similares.";
	var splitTitle1 = doc.splitTextToSize(text1, 160);
	doc.text(30, 90, splitTitle1);
	/* Servicio Adicional 2 */
	var text3 =
		"Los siguientes servicios pueden ser tomados adicionalmente, siempre y cuando no estén ya incluídos en el plan contratado inicialmente. Para saber qué servicios están en su plan, debe revisar el inicio del articulo PRIMERO.";
	var splitTitle3 = doc.splitTextToSize(text3, 190);
	doc.text(10, 105, splitTitle3);

	doc.setFont("arial", "bold");
	doc.text("A) Servicio Reviso tu PYME", 30, 117);
	doc.line(30, 118, 83, 118);
	doc.setFont("arial", "normal");
	var text2 =
		"Revisión sobre la situación de la empresa entregando un diagnóstico idealmente en una llamada o reunión para aclarar las dudas y entregar una cotización para solucionar el problema.";
	var splitTitle2 = doc.splitTextToSize(text2, 160);
	doc.text(30, 125, splitTitle2);
	doc.text("El valor de este servicio es de: $20.000.-", 30, 141);

	doc.setFont("arial", "bold");
	doc.text("B) Servicio de Rectificación de F29", 30, 150);
	doc.line(30, 151, 93, 151);
	doc.setFont("arial", "normal");
	doc.text("El valor de este servicio es de: $20.000 por mes.", 30, 157);

	doc.setFont("arial", "bold");
	doc.text("C) Servicio de Declaraciones F29 Pendientes", 30, 166);
	doc.line(30, 167, 110, 167);
	doc.setFont("arial", "normal");
	doc.text("El valor de este servicio es de: $20.000 por mes.", 30, 173);

	doc.setFont("arial", "bold");
	doc.text("D) Otros Servicios no incluídos en Plan", 30, 183);
	doc.line(30, 184, 100, 184);
	doc.setFont("arial", "normal");
	var text4 =
		"Los siguientes documentos: Carpeta tributaria, f30, Declaraciones de Ingresos “DIN” (hasta 3 documentos DIN para un respectivo mes), generación de cédulas E-Rut, comprobante de vacaciones, certificados y comprobantes varios, facturas, notas de crédito y débito, boletas de productos y/o servicios, boletas de honorarios; así como cualquier otro documento que no haya sido mencionado en los elementos básicos incluídos en el plan que usted contrata, tendrán un valor adicional de $5.000 cada uno. Por complejidad el F30-1 tendrá un valor de $10.000 cada uno.-";
	var splitTitle4 = doc.splitTextToSize(text4, 160);
	doc.text(30, 190, splitTitle4);

	doc.setFont("arial", "bold");
	doc.text("SEGUNDO: DEL NO PAGO", 10, 234);
	doc.line(10, 235, 65, 235);
	doc.setFont("arial", "normal");
	var text5 =
		"Si bien, indistintamente de lo siguiente, se aplicará lo descrito en los articulos QUINTO, SEXTO y SEPTIMO, es importante mencionar que la prestación de servicios se realizará continuamente de forma independientemente del pago de estos por un plazo máximo de hasta 3 meses. Si el cliente a pesar de este plazo decide no pagar, se le dará aviso de corte del servicio y este no continuará hasta que el cliente haya cancelado lo adeudado. Las no declaraciones realizadas a tiempo involucran multas e intereses por parte del SII, que no serán asumidas por DeNegocios.cl de tratarse de declaraciones atrasadas producto del no pago de servicios previos.";
	var splitTitle5 = doc.splitTextToSize(text5, 190);
	doc.text(10, 242, splitTitle5);

	doc.addImage(direccionfinal, "PNG", 80, 280, 45, 10);

	/* Third Page is from here */

	doc.addPage();
	doc.addImage(logopequeno, "PNG", 3, 3, 25, 10);
	doc.setFont("arial", "bold");
	doc.text("TERCERO", 10, 16);
	doc.line(10, 17, 30, 17);
	doc.setFont("arial", "normal");
	doc.text(
		"No están incluidos dentro de los servicios señalados anteriormente:",
		10,
		23
	);
	doc.text("- Recepción de dinero y pago de Impuestos o Cotizaciones.", 15, 29);
	doc.text("- Retiro de Documentos.", 15, 35);
	var text1 =
		"- Contratos Laborales Especiales o con cláusulas particulares que requieran la intervención de un abogado para su redacción o que estén por fuera de los modelos actualmente estandarizados para la gran mayoría de las empresas.";
	var splitTitle1 = doc.splitTextToSize(text1, 185);
	doc.text(15, 41, splitTitle1);
	doc.text(
		"- Trámites o Procesos relacionados a la Inspección del Trabajo, dígase F30, F30-1 y similares.",
		15,
		56
	);
	doc.text(
		"- Representación ante el Servicio de Impuestos Internos ante fiscalizaciones y otros.",
		15,
		62
	);
	doc.text(
		"- Emisión de Facturas y otros Documentos Tributarios Electrónicos o en Físico.",
		15,
		68
	);
	doc.text(
		"- Trámites electrónicos o presenciales ante la Inspección del Trabajo, AFC,Fonasa, Isapres, AFP.",
		15,
		74
	);
	var text2 =
		"- Preparación de Inventarios, Balances, auditorias, defensorías tributarias, confección de declaraciones juradas, confecciones de declaraciones de Rentas, reclamación de impuestos.";
	var splitTitle2 = doc.splitTextToSize(text2, 185);
	doc.text(15, 80, splitTitle2);
	var text2 =
		"Dado el caso, se pagarán como extraordinarios los trabajos anteriores si Asesorias e Inversiones DN SpA acepta realizar las gestiones. Esto, previo acuerdo por escrito entre las partes.";
	var splitTitle2 = doc.splitTextToSize(text2, 190);
	doc.text(10, 98, splitTitle2);
	doc.setFont("arial", "bold");
	doc.text("CUARTO", 10, 115);
	doc.line(10, 116, 30, 116);
	doc.setFont("arial", "normal");
	var text3 =
		"En lo que respecta al período que llamaremos “Declaración de Renta”. Esto es: El Balance Anual, la Declaración de Impuesto a la Renta F22, las declaraciones Juradas de Horarios, Sueldos y Retiros, además de la Determinación de Capital Propio Tributario, Renta Líquida Imponible y FUT, documentos y libros necesarios para la Confección del F/22 Declaración de Renta; y otros relacionados a la “Declaración de Renta”.";
	var splitTitle3 = doc.splitTextToSize(text3, 190);
	doc.text(10, 122, splitTitle3);
	var text4 =
		"Lo anterior, podrá ser tomado por EL CLIENTE como servicio adicional prestado por Asesorias e Inversiones DN SpA. Esto es opcional y no involucra una obligación para el cliente. Si es que el cliente decide aceptar este servicio adicional, este tendrá un costo adicional desde $178.500 para empresa y no tiene relación alguna con el costo del Servicio mensual. Las condiciones de este servicio, así como su valor final, serán confirmados por Asesorias e Inversiones DN SpA. Por lo demás, el cliente podrá consultar por ellos y contratar en cualquier momento. Asesorias e Inversiones DN SpA no se hará responsable por multas asociadas a la no presentación de “Declaración de Renta” asociadas a la No Contratación de este Servicio con nuestra empresa.";
	var splitTitle4 = doc.splitTextToSize(text4, 190);
	doc.text(10, 145, splitTitle4);
	doc.setFont("arial", "bold");
	doc.text("QUINTO", 10, 185);
	doc.line(10, 186, 30, 186);
	doc.setFont("arial", "normal");
	var text5 =
		"Los honorarios del servicio serán informados por el prestador a su debido tiempo, esto a lo menos 5 días antes del día 30 de cada mes. Los honorarios del servicio deberán ser cancelados directamente a Asesorias e Inversiones DN SpA como máximo los días 5 del mes siguiente a lo informado, en dinero efectivo directamente en las oficinas del prestador, transferencia o depósito a la cuenta que el prestador indique.";
	var splitTitle5 = doc.splitTextToSize(text5, 190);
	doc.text(10, 192, splitTitle5);
	var text6 =
		"Si el pago se realiza vía transferencia o depósito, deberán ser realizados a la siguiente cuenta:";
	var splitTitle6 = doc.splitTextToSize(text6, 190);
	doc.text(10, 213, splitTitle6);
	doc.setFont("arial", "bold");
	doc.text("BANCO: BANCO ESTADO", 20, 221);
	doc.text("Nombre Empresa: ASESORIAS E INVERSIONES DN SpA", 20, 228);
	doc.text("Rut: 77.526.796-8", 20, 235);
	doc.text("Tipo de Cuenta: Chequera electrónica // cuenta vista", 20, 242);
	doc.text("Número de cuenta: 90270930912", 20, 249);
	doc.text("Correo: pagos@denegocios.cl", 20, 256);
	doc.setFont("arial", "normal");
	doc.text(
		"Asesorias e Inversiones DN SpA, tras recibir el pago, podrá emitir una factura por el servicio.",
		10,
		266
	);
	doc.addImage(direccionfinal, "PNG", 80, 280, 45, 10);

	doc.addPage();
	doc.addImage(logopequeno, "PNG", 3, 3, 25, 10);
	doc.addImage(direccionfinal, "PNG", 80, 285, 45, 10);
	var text1 =
		"Las partes convienen en que las tarifas indicadas en la tabla única de este contrato, esto es en el Artículo Segundo, pueden sufrir cambios en el tiempo. Estos cambios en la tarifa serán convenidos por las partes de común acuerdo, en el entendido que en caso de que las partes no lograran llegar a un acuerdo en el incremento de la prestación, el presente contrato podrá darse por terminado sin responsabilidad de alguna para las partes.";
	var splitTitle1 = doc.splitTextToSize(text1, 190);
	doc.text(10, 20, splitTitle1);
	doc.setFont("arial", "bold");
	doc.text("SEXTO:VIGENCIA", 10, 45);
	doc.line(10, 46, 50, 46);
	doc.setFont("arial", "normal");
	var text2 =
		"La vigencia de este Contrato será por 6 meses (seis meses) de corrido, iniciando a partir del día en el que este documento se firme. Tendrá renovación automática por períodos iguales de tiempo, si es que ninguna de las partes se proclama. Asimismo el PRESTADOR se obliga a entregar al CLIENTE a más tardar el día en que sufra efectos la terminación a que se refiere esta cláusula o la rescisión a que se refiere la cláusula Décima Quinta de este contrato, toda la documentación e información confidencial del CLIENTE que tenga en su poder con motivo de LOS SERVICIOS, así como un informe detallado de todas las acciones ejercidas en cada uno de los asuntos que le hayan sido encomendados por virtud de este contrato.";
	var splitTitle2 = doc.splitTextToSize(text2, 190);
	doc.text(10, 52, splitTitle2);
	var text3 =
		"El presente contrato podrá ponerse término por ambas partes con 15 días corridos de anticipación vía escrita, para la entrega de la información por parte de Asesorias e Inversiones DN SpA., y por parte del cliente el pago de los servicios efectuados hasta esa fecha.";
	var splitTitle3 = doc.splitTextToSize(text3, 190);
	doc.text(10, 90, splitTitle3);
	doc.setFont("arial", "bold");
	doc.text("SEPTIMO: CLAUSULA PENAL ", 10, 110);
	doc.line(10, 111, 70, 111);
	doc.setFont("arial", "normal");
	var text4 =
		"El no pago de uno o cualquiera de los montos señalados en la cláusula cuarta Autorizará a Asesorias e Inversiones DN SpA, Rol único tributario número 77.526.796-8, para que en caso de simple retardo, mora o incumplimiento de las obligaciones contraídas en documentos tales como: contratos, facturas, órdenes de compra, solicitudes de compra, guías de pedidos, cartas de porte, pagarés, letras de cambio u otros: mis datos personales y los demás derivados de dichos de dichos documentos puedan ser ingresados, procesados, tratados y comunicados a terceros sin restricciones, en el registro o banco de datos de sistemas de información comercial y además dará derecho a Asesorias e Inversiones DN SpA a poner término en forma unilateral a la prestación de los servicios que son objeto del presente contrato, liberando al cliente de la responsabilidad procesal posterior a su incumplimiento; aceptando desde ya la renuncia al mandato judicial en las causas señaladas en la cláusula primera. Dará derecho además a la empresa a exigir el cobro del total de lo adeudado como una obligación pura y simple, sin perjuicios de las demás acciones legales que correspondan.";
	var splitTitle4 = doc.splitTextToSize(text4, 190);
	doc.text(10, 117, splitTitle4);
	doc.setFont("arial", "bold");
	doc.text("OCTAVA.- NO EXCLUSIVIDAD PARA EL PRESTADOR.", 10, 175);
	doc.line(10, 176, 120, 176);
	doc.setFont("arial", "normal");
	var text5 =
		"Queda expresamente pactado que el presente contrato no crea exclusividad entre las partes, por lo que sí es voluntad del CLIENTE, éste podrá contratar cualquier servicio igual o similara los proporcionados por EL PRESTADOR, con la persona o personas, físicas o morales, nacionales o extranjeras que a sus intereses convenga, sin necesidad de notificarlo al PRESTADOR.";
	var splitTitle5 = doc.splitTextToSize(text5, 190);
	doc.text(10, 182, splitTitle5);
	doc.setFont("arial", "bold");
	doc.text("NOVENA.- CONFIDENCIALIDAD.", 10, 205);
	doc.line(10, 206, 75, 206);
	doc.setFont("arial", "normal");
	var text5 =
		"EL PRESTADOR y el personal de éste que ponga a disposición del CLIENTE para la prestación de LOS SERVICIOS, previo a la firma del presente contrato y durante su vigencia, tendrán acceso a diversa información del CLIENTE que se entenderá como información confidencial, por loque el PRESTADOR se obliga a no divulgar por ningún medio y a ninguna persona, dicha información, ni los datos y resultados obtenidos como motivo de la prestación de LOS SERVICIOS, sin autorización expresa y por escrito del CLIENTE.";
	var splitTitle5 = doc.splitTextToSize(text5, 190);
	doc.text(10, 212, splitTitle5);
	var text6 =
		"La obligación del PRESTADOR a que se refiere el párrafo anterior, surtirá sus efectos a partir de la fecha de la firma del presente contrato y durante su vigencia, de un año posterior a la terminación de la relación de prestación de servicios entre las partes, con el fin de proteger la confidencialidad de todos y cada uno de los intereses del CLIENTE.";
	var splitTitle6 = doc.splitTextToSize(text6, 190);
	doc.text(10, 240, splitTitle6);
	var text7 =
		"El PRESTADOR se obliga incondicionalmente a hacer extensiva estas disposiciones a sus empleados o cualquier persona que necesariamente debe tener acceso a información confidencial, por lo que EL PRESTADOR deberá procurar los medios adecuados para poder comprometer a las personas ya mencionadas, a observar estrictamente estos acuerdos, de lo contrario, el PRESTADOR o cualquiera de las personas aquí señaladas se harán acreedoras a lo dispuesto por la legislación aplicable para tal efecto.";
	var splitTitle7 = doc.splitTextToSize(text7, 190);
	doc.text(10, 263, splitTitle7);

	doc.addPage();
	doc.addImage(logopequeno, "PNG", 3, 3, 25, 10);
	doc.addImage(direccionfinal, "PNG", 80, 285, 45, 10);
	doc.setFont("arial", "bold");
	doc.text("DÉCIMA.- INDEPENDENCIA DE LAS PARTES.", 10, 20);
	doc.line(10, 21, 105, 21);
	doc.setFont("arial", "normal");
	var text1 =
		"El presente contrato no presentade manera tácita o expresa, cualquier tipo de alianza, sociedad, asociación, asociación en participación, o transferencia de acciones, o participación de alguna en la capital social y/o patrimonio según aplique, de cada una de las partes, así como tampoco aplica una relación laboral entre éstas, por lo que se manifiesta en este acto la independencia de ambas partes respecto a sus derechos y obligaciones derivados de la celebración de este contrato, en relación con terceros.";
	var splitTitle1 = doc.splitTextToSize(text1, 190);
	doc.text(10, 27, splitTitle1);
	var text2 =
		"No obstante lo anterior, el PRESTADOR se obliga a proporcionar los servicios objeto de este contrato, de la manera que EL CLIENTE se lo solicite o instruye y siempre en escrito apego a las leyes y procedimientos que en su caso sean aplicables.";
	var splitTitle2 = doc.splitTextToSize(text2, 190);
	doc.text(10, 53, splitTitle2);
	doc.setFont("arial", "bold");
	doc.text("DÉCIMA PRIMERA.- NOTIFICACIONES.", 10, 70);
	doc.line(10, 71, 80, 71);
	doc.setFont("arial", "normal");
	var text3 =
		"Cualquier tipo de notificación entre las partes deberá ser por escrito vía correo electrónico, copiando expresamente a contabilidad@denegocios.cl y a contacto@denegocios.cl.";
	var splitTitle3 = doc.splitTextToSize(text3, 190);
	doc.text(10, 77, splitTitle3);
	doc.setFont("arial", "bold");
	doc.text("DÉCIMA SEGUNDA.- REVISIONES Y AUDITORÍAS.", 10, 89);
	doc.line(10, 90, 115, 90);
	doc.setFont("arial", "normal");
	var text4 =
		"Las partes convienen que el CLIENTE podrá en todo momento efectuar, por medio de las personas que estimen conveniente, revisiones, auditorías, verificaciones y podrás solicitar al PRESTADOR la elaboración y entrega de todo tipo de reportes y controles relacionados con la prestación de LOS SERVICIOS y el PRESTADOR se obliga a dar todas las facilidades necesarias para la realización de las revisiones, auditorías y verificaciones y entrega al CLIENTE en la forma y plazo que éste solicite los citados reportes y/o controles.";
	var splitTitle4 = doc.splitTextToSize(text4, 190);
	doc.text(10, 96, splitTitle4);
	doc.setFont("arial", "bold");
	doc.text("DÉCIMO TERCERA.- MODIFICACIONES AL CONTRATO.", 10, 122);
	doc.line(10, 123, 130, 123);
	doc.setFont("arial", "normal");
	doc.text(
		"Este contrato no podrá ser modificado o alterado, excepto mediante acuerdo por escrito, firmado por ambas partes.",
		10,
		128
	);
	doc.setFont("arial", "bold");
	doc.text("DÉCIMO CUARTA.- MANIFESTACIÓN DE LA VOLUNTAD.", 10, 135);
	doc.line(10, 136, 130, 136);
	doc.setFont("arial", "normal");
	var text5 =
		"El presente contrato constituye el acuerdo de voluntades total y final entre las partes, por lo que cualquier aviso, oferta, comunicación, contrato, convenio o acuerdo en general sea verbal y/o escrito, adoptado entre las partes con anterioridad a la firma del presente, queda a partir de la fecha de firma de este instrumento sin valor alguno.";
	var splitTitle5 = doc.splitTextToSize(text5, 190);
	doc.text(10, 141, splitTitle5);
	doc.setFont("arial", "bold");
	doc.text("DÉCIMO QUINTA", 10, 157);
	doc.line(10, 158, 48, 158);
	doc.setFont("arial", "normal");
	var text6 =
		"Las multas e intereses por el pago fuera de plazo de las obligaciones tributarias y previsionales del cliente serán de su exclusiva responsabilidad, no así, ya sea por olvido u omisión involuntaria de parte de Asesorias e Inversiones DN SpA, quien será este último el que costeara dichos cargos.";
	var splitTitle6 = doc.splitTextToSize(text6, 190);
	doc.text(10, 162, splitTitle6);

	doc.setFont("arial", "bold");
	doc.text("DÉCIMA SEXTA.- JURISDICCIÓN.-", 10, 180);
	doc.line(10, 181, 80, 181);
	doc.setFont("arial", "normal");
	var text7 =
		"Para la interpretación y cumplimiento de este contrato, las partes se someten expresamente a las leyes y tribunales de la ciudad de Santiago, Chile, renunciando a cualquier otro fuero que pudiera corresponderles por razón de sus domicilios presentes o futuros o por cualquierotra causa.";
	var splitTitle7 = doc.splitTextToSize(text7, 190);
	doc.text(10, 186, splitTitle7);
	var text8 = `Enteradas de su contenido y alcance legal y no existiendo error, dolo, mala fe o violencia, las partes firman de conformidad, por duplicado el presente Contrato para el servicio de Contabilidad ${planContratado}, en la ciudad de Santiago, Chile a ${fecha}, fecha en la cual entra en vigor.`;
	var splitTitle8 = doc.splitTextToSize(text8, 190);
	doc.text(10, 202, splitTitle8);

	doc.line(10, 230, 90, 230);
	doc.text("Asesorias e Inversiones DN SpA", 10, 235);
	doc.text("77.526.796-8", 10, 240);
	doc.text(`P.p Emilio Jelves`, 10, 245);
	doc.text(`17.011.079-K`, 10, 250);
	doc.line(10, 270, 90, 270);
	doc.text(`${nombre}`, 10, 275);
	doc.text(`${rut}`, 10, 280);

	doc.save(`Contrato Contabilidad para ${nombre}.pdf`);
};
