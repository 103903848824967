import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../store/AppContext';
import { formatRut, validateRut } from '../../Helper/RutUtils';

const AgregarNuevoClientePatentes = ({ isOpen, onClose }) => {
  const { store, actions } = useContext(Context);
  const [isRutVinculado, setIsRutVinculado] = useState(false);
  const [isRutValido, setIsRutValido] = useState(false);
  const [empresasVinculadas, setEmpresasVinculadas] = useState([]);
  const [empresaSeleccionada, setEmpresaSeleccionada] = useState('');
  const [isEmpresaSeleccionada, setIsEmpresaSeleccionada] = useState(false);
  
  const initialFormData = {
    codigo: '',
    numeroExpediente: '',
    rut: '',
    razonSocial: '',
    correo: '',
    estado: 'Pendiente',
    revisionEstado: new Date().toISOString().split('T')[0],
    terminoComentarios: ''
  };

  const [formData, setFormData] = useState(initialFormData);
  const [rutError, setRutError] = useState('');
  const [formErrors, setFormErrors] = useState({
    codigo: '',
    numeroExpediente: '',
    correo: '',
    terminoComentarios: ''
  });

  useEffect(() => {
    if (isOpen) {
      const fechaActual = new Date().toISOString().split('T')[0];
      setFormData({
        ...initialFormData,
        revisionEstado: fechaActual
      });
    } else {
      setFormData(initialFormData);
    }
    // Resetear todos los estados adicionales
    setRutError('');
    setIsRutVinculado(false);
    setIsRutValido(false);
    setEmpresasVinculadas([]);
    setEmpresaSeleccionada('');
    setIsEmpresaSeleccionada(false);
    setFormErrors({
      codigo: '',
      numeroExpediente: '',
      correo: '',
      terminoComentarios: ''
    });
  }, [isOpen]);

  const handleChange = (e) => {
    if (e.target.name === 'rut') {
      const formattedRut = formatRut(e.target.value);
      setFormData({ ...formData, rut: formattedRut });
      setRutError('');
      // Reset all states when RUT is modified
      setIsRutValido(false);
      setIsRutVinculado(false);
      setEmpresasVinculadas([]);
      setEmpresaSeleccionada('');
      setIsEmpresaSeleccionada(false);
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleRutBlur = () => {
    if (validateRut(formData.rut)) {
      setIsRutValido(true);
      setRutError('');
    } else {
      setIsRutValido(false);
      setRutError('Formato inválido, revise su RUT.');
      // Reset all states when RUT is invalid
      setIsRutVinculado(false);
      setEmpresasVinculadas([]);
      setEmpresaSeleccionada('');
      setIsEmpresaSeleccionada(false);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      codigo: '',
      numeroExpediente: '',
      correo: '',
      terminoComentarios: ''
    };

    // Validar código
    if (formData.codigo && !/^\d+$/.test(formData.codigo)) {
      newErrors.codigo = 'El código debe contener solo números';
      isValid = false;
    }

    // Validar número de expediente
    if (formData.numeroExpediente && !/^\d+$/.test(formData.numeroExpediente)) {
      newErrors.numeroExpediente = 'El número de expediente debe contener solo números';
      isValid = false;
    }

    // Validar correo
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.correo)) {
      newErrors.correo = 'Por favor, ingrese un correo electrónico válido';
      isValid = false;
    }

    // Validar término/comentarios
    if (formData.terminoComentarios.length > 100) {
      newErrors.terminoComentarios = 'El comentario no puede exceder los 100 caracteres';
      isValid = false;
    }

    setFormErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateRut(formData.rut)) {
      setRutError('Por favor, ingrese un RUT válido con el formato xx.xxx.xxx-x');
      return;
    }

    if (!validateForm()) {
      return;
    }

    try {
      const fechaActual = new Date().toLocaleString('es-ES', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      }).split('/').reverse().join('-');
  
      const usuarioCreador = `${store.usuarioActual.nombre} ${store.usuarioActual.apellido}`;
  
      const nuevaPatente = {
        ...formData,
        codigo: formData.codigo ? [[parseInt(formData.codigo), fechaActual]] : [],
        numeroExpediente: formData.numeroExpediente ? [[parseInt(formData.numeroExpediente), fechaActual]] : [],
        fechaCreacion: fechaActual,
        fechaEdicion: [[fechaActual, usuarioCreador]],
        usuarioCreador: usuarioCreador
      };
  
      await actions.crearPatente(nuevaPatente);

      // Solo lanzar error si NO es el error específico de data.map
      if (store.error && !store.error.includes('data.map is not a function')) {
        throw new Error(`Error al crear patente: ${store.error}`);
      }
  
      // Primero verificamos el tipo de empresa seleccionada
      if (empresaSeleccionada === 'no_existe') {
        console.log('Se creará una nueva patente sin vincular a cliente DT existente');
        
        // Llamada a Zapier para notificar nueva empresa
        await fetch("https://hooks.zapier.com/hooks/catch/2499772/2atl1sd/", {
          method: "POST",
          body: JSON.stringify({
            rut: formData.rut,
            razonSocial: formData.razonSocial,
            correo: formData.correo,
            usuarioCreador: usuarioCreador
          }),
        });
      } else if (empresaSeleccionada) {
        // Si hay un ID válido, actualizamos el cliente DT
        await actions.editarClienteDt(
          empresaSeleccionada, // id
          "", // razon
          "", // rut
          "", // vigente
          "", // correo
          "", // correoSecundario
          "", // correoTerciario
          "", // fono
          "", // whatsapp
          "", // representante
          "", // rutRepresentante
          "", // fechaContratacion
          "", // erpyme
          "", // p
          "", // sacar
          "", // dicom
          "", // contratoActualizado
          "Si", // patente
          "", // libre
          "", // mesesPagados
          "", // mesesPagados2023
          ""  // tipoPago
        );
        if (store.error) {
          throw new Error(`Error al actualizar cliente DT: ${store.error}`);
        }
      }
  
      setFormData(initialFormData); // Reiniciar el formulario
      onClose();
      
    } catch (error) {
      alert(error.message || 'Ha ocurrido un error al procesar la solicitud');
    }    
  };

  const handleVincularRut = async () => {
    if (!formData.rut || !isRutValido) {
      setRutError('Debe ingresar un RUT válido antes de vincular');
      return;
    }
    
    // Procesar el RUT: remover puntos pero mantener el guión
    const rutProcesado = formData.rut.replace(/\./g, '');
    
    // Llamar a getBusquedaDt con el RUT procesado
    await actions.getBusquedaDt(rutProcesado);
    
    // Procesar los resultados de la búsqueda
    const empresasEncontradas = store.clientesDt && store.clientesDt.length > 0
      ? store.clientesDt.map(objeto => ({
          id: objeto.id,
          texto: `${objeto.razon}, rut: ${objeto.rut}`
        }))
      : [];
    
    setIsRutVinculado(true);
    setEmpresasVinculadas([
      { id: 'no_existe', texto: 'Empresa no Existe en DT (Será Reportada a Responsables)' },
      ...empresasEncontradas
    ]);
  };

  const handleSeleccionEmpresa = () => {
    if (!empresaSeleccionada) {
      return;
    }
    console.log('ID de empresa seleccionada:', empresaSeleccionada);
    setIsEmpresaSeleccionada(true);
  };

  const handleEmpresaChange = (e) => {
    setEmpresaSeleccionada(e.target.value);
    setIsEmpresaSeleccionada(false);
  };

  if (!isOpen) return null;

  return (
    <div className="reveal-overlay" style={{display: 'block'}}>
      <div className="reveal" style={{display: 'block'}}>
        <h2>Agregar Nueva Patente</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid-x grid-padding-x">
            <div className="cell small-6">
              <label>
                Código
                <input type="text" name="codigo" value={formData.codigo} onChange={handleChange} />
              </label>
              {formErrors.codigo && <small style={{color: 'red', display: 'block'}}>{formErrors.codigo}</small>}
            </div>
            <div className="cell small-6">
              <label>
                Número de Expediente
                <input type="text" name="numeroExpediente" value={formData.numeroExpediente} onChange={handleChange} />
              </label>
              {formErrors.numeroExpediente && <small style={{color: 'red', display: 'block'}}>{formErrors.numeroExpediente}</small>}
            </div>
            <div className="cell small-6">
              <label>
                RUT *
                <input 
                  type="text" 
                  name="rut" 
                  value={formData.rut} 
                  onChange={handleChange}
                  onBlur={handleRutBlur}
                  maxLength={12}
                  placeholder="Ej: 17.011.079-K"
                  required 
                />
              </label>
              {rutError && <small style={{color: 'red', display: 'block'}}>{rutError}</small>}
            </div>
            <div className="cell small-6">
              <label>
                Razón Social *
                <input type="text" name="razonSocial" value={formData.razonSocial} onChange={handleChange} required />
              </label>
            </div>
            <div className="cell small-6">
              <label>
                Correo Electrónico *
                <input type="email" name="correo" value={formData.correo} onChange={handleChange} required />
              </label>
              {formErrors.correo && <small style={{color: 'red', display: 'block'}}>{formErrors.correo}</small>}
            </div>
            <div className="cell small-6">
              <label>
                Estado
                <select name="estado" value={formData.estado} onChange={handleChange}>
                  <option value="En Proceso">En Proceso</option>
                  <option value="Terminada">Terminada</option>
                  <option value="Rechazada">Rechazada</option>
                  <option value="Pendiente">Pendiente</option>
                  <option value="Devolucion">Devolucion</option>
                  <option value="Cliente No Responde">Cliente No Responde</option>
                  <option value="No Sigue Proceso">No Sigue Proceso</option>
                  <option value="Ultima Etapa">Ultima Etapa</option>
                  <option value="En Revision">En Revision</option>
                </select>
              </label>
            </div>
            <div className="cell small-6">
              <label>
                Fecha de Revisión de Estado
                <input 
                  type="date" 
                  name="revisionEstado" 
                  value={formData.revisionEstado} 
                  onChange={handleChange} 
                />
              </label>
            </div>
            <div className="cell">
              <label>
                Término / Comentarios
                <textarea 
                  name="terminoComentarios" 
                  value={formData.terminoComentarios} 
                  onChange={handleChange} 
                  rows="3"
                ></textarea>
                <small>{formData.terminoComentarios.length}/100 caracteres</small>
              </label>
              {formErrors.terminoComentarios && <small style={{color: 'red', display: 'block'}}>{formErrors.terminoComentarios}</small>}
            </div>
            
            <div className="cell" style={{ backgroundColor: '#f4e4cd', padding: '15px', margin: '20px 0' }}>
              <small>*Agrega el Rut antes de Vincular</small>
                <div className="grid-x grid-padding-x align-middle align-center" style={{ marginBottom: '10px' }}>
                  <div className="cell small-8 text-center">
                    <button 
                    type="button" 
                    className="button small"
                    onClick={handleVincularRut}
                    disabled={!isRutValido}
                  >
                    Vincular Rut en DT
                  </button>
                  &nbsp;
                  <button 
                    type="button" 
                    className="button small"
                    onClick={handleSeleccionEmpresa}
                    disabled={!isRutVinculado || !empresaSeleccionada}
                  >
                    Seleccionar Empresa
                  </button>
                  &nbsp; &nbsp;
                  <span style={{ color: 'green', fontSize: '40px' }}>
                    {isEmpresaSeleccionada ? '✓' : ''}
                  </span>
                </div>
              </div>
              <div className="grid-x grid-padding-x">
                <div className="cell">
                  <select 
                    className="form-control"
                    value={empresaSeleccionada}
                    onChange={handleEmpresaChange}
                    disabled={!isRutVinculado}
                  >
                    <option value="">
                      {isRutVinculado ? "Selecciona una Opción..." : "Empresa no Vinculada"}
                    </option>
                    {empresasVinculadas.map((empresa, index) => (
                      <option key={index} value={empresa.id}>{empresa.texto}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="grid-x grid-padding-x">
            <div className="cell small-6">
              <button 
                type="submit" 
                className="button expanded"
                disabled={!isEmpresaSeleccionada}
              >
                Agregar Patente
              </button>
            </div>
            <div className="cell small-6">
              <button type="button" className="button secondary expanded" onClick={onClose}>Cancelar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AgregarNuevoClientePatentes;
