import React, { useState, useContext, useEffect } from "react";
import { Context } from "../../store/AppContext";

// Este formulario es de Administracion

const CreacionUsuario = ({ setCrear, setAnimarModal, animarModal }) => {
	const { store, actions } = useContext(Context);
	const [datos, setDatos] = useState({
		nombre: "",
		apellido: "",
		correo: "",
		clave: "",
		tipo: "Vendedor",
	});
	const [alertPrincipal, setAlertPrincipal] = useState(false);
	const [alertCorreo, setAlertCorreo] = useState(false);

	const HandlerCerrar = (event) => {
		setAnimarModal(false);

		setTimeout(() => {
			setCrear(false);
		}, 500);
	};

	useEffect(() => {
		if (store.witch === false) {
			actions.setWitch(true);
		}
	}, []);

	const HandlerCrear = async (event) => {
		if (
			datos.nombre.trim() === "" ||
			datos.apellido.trim() === "" ||
			datos.correo.trim() === "" ||
			datos.clave.trim() === "" ||
			datos.tipo.trim() === "" ||
			datos.nombre === null ||
			datos.apellido === null ||
			datos.correo === null ||
			datos.clave === null ||
			datos.tipo === null
		) {
			setAlertPrincipal(true);
		} else {
			if (alertCorreo) {
				setAlertPrincipal(true);
			}
			if (!alertCorreo) {
				try {
					await actions.crearUsuario(
						datos.nombre,
						datos.apellido,
						datos.correo,
						datos.clave,
						datos.tipo
					);

					// Si la creación fue exitosa, actualizar la lista
					await actions.getUsuarios();

					// Cerrar el modal
					setAnimarModal(false);
					setTimeout(() => {
						setCrear(false);
					}, 500);

				} catch (error) {
					alert(error.message || "Error al crear usuario");
				}
			}
		}
	};

	const HandlerModificacionDatos = (event) => {
		const correo = event.target.value;
		const validRegex =
			/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

		if (event.target.name === "correo") {
			if (correo.match(validRegex)) {
				setDatos({ ...datos, [event.target.name]: event.target.value });
				setAlertCorreo(false);
				return;
			}
			if (!correo.match(validRegex)) {
				setDatos({ ...datos, [event.target.name]: event.target.value });
				setAlertCorreo(true);
				return;
			}
		}

		setDatos({ ...datos, [event.target.name]: event.target.value });
	};

	return (
		<div className='modal'>
			<div className='cerrar-modal'>
				<button
					className='close-button'
					aria-label='Dismiss alert'
					type='button'
					data-close
					onClick={(e) => HandlerCerrar(e)}
				>
					<span aria-hidden='true'>&times;</span>
				</button>
			</div>
			<form className={`formulario ${animarModal ? "animar" : "cerrar"}`}>
				<div style={{ display: "grid", placeContent: "center" }}>
					<h4 className='text-right' style={{ color: "white" }}>
						Ingresa los Datos solicitados para crear un Usuario
					</h4>
					<label>
						Nombre
						<input
							type='text'
							value={datos.nombre}
							name='nombre'
							onChange={(e) => HandlerModificacionDatos(e)}
						/>
					</label>
					<label>
						Apellido
						<input
							type='text'
							value={datos.apellido}
							name='apellido'
							onChange={(e) => HandlerModificacionDatos(e)}
						/>
					</label>
					<label>
						Email
						<input
							type='email'
							value={datos.correo}
							name='correo'
							onChange={(e) => HandlerModificacionDatos(e)}
							autoComplete="off"
						/>
					</label>
					<label>
						Clave
						<input
							type='password'
							value={datos.clave}
							name='clave'
							onChange={(e) => HandlerModificacionDatos(e)}
							autoComplete="new-password"
						/>
					</label>
					<div className=''>
						<label className='form-label' htmlFor='tipo'>
							Elige Tipo de Usuario
						</label>
						<select
							className='form-select'
							id='tipo'
							name='tipo'
							onChange={(e) => HandlerModificacionDatos(e)}
						>
							<option>Vendedor</option>
							<option>Administrador</option>
							<option>Cobranza</option>
							<option>Soporte</option>
							<option>Contabilidad</option>
						</select>
					</div>
					{alertPrincipal ? (
						<div className='callout alert text-center'>
							Por favor, todos los datos deben ser completados
						</div>
					) : null}
					{alertCorreo ? (
						<div className='callout alert text-center'>Correo Incorrecto</div>
					) : null}
					<div className='button-group align-right'>
						<a className='button primary' onClick={(e) => HandlerCrear(e)}>
							Crear Usuario
						</a>
					</div>
				</div>
			</form>
		</div>
	);
};

export default CreacionUsuario;
