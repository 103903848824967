import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/AppContext";
import "../../../styles/App.css";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../store/config";

const FormularioLogin = ({ setBienvenida }) => {
	const { store, actions } = useContext(Context);
	const [error, setError] = useState(null);
	const [credentials, setCredentials] = useState({
		email: '',
		password: ''
	});

	useEffect(() => {
		if (store.usuarioActual != null) {
			setBienvenida(true);
		}
	}, [store.usuarioActual]);

	const HandlerLoginGoogle = async () => {
		setError(null);
		actions.setSpinner(); // Iniciar spinner
		const result = await actions.loginFirebase();
		if (!result.success) {
			setError(result.error);
			actions.setSpinner(); // Apagar spinner en caso de error
		}
	};

	const handleEmailPasswordLogin = async (e) => {
		e.preventDefault();
		setError(null);
		actions.setSpinner(); // Iniciar spinner

		const result = await actions.loginFirebaseEmail(credentials.email, credentials.password);
		
		if (!result.success) {
			setError(result.error);
			actions.setSpinner(false); // Apagar spinner en caso de error
		}
	};

	const handleInputChange = (e) => {
		setCredentials({
			...credentials,
			[e.target.name]: e.target.value
		});
	};

	return (
		<div className='grid-x grid-margin-x'>
			<div className='cell small-4 large-offset-4'>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<div
					className='log-in-form'
					style={{
						backgroundColor: "rgba(46, 138, 199, 0.5)",
						padding: "20px",
					}}
				>
					<h4
						className='text-center'
						style={{ color: "white", textShadow: "1px 1px black" }}
					>
						Ingresa con tu Cuenta de DeNegocios.cl
					</h4>
					<br />
					{error && (
						<div className="alert alert-danger text-center">
							{error}
						</div>
					)}
					<br />
					<div className='text-center'>
						<button 
							className='button expanded'
							onClick={HandlerLoginGoogle}
							style={{
								backgroundColor: "#fff",
								color: "#757575",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								gap: "10px",
								border: "1px solid #ddd"
							}}
						>
							<img 
								src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
								alt="Google logo" 
								style={{ width: "18px", height: "18px" }}
							/>
							Iniciar sesión con Google
						</button>
					</div>
					<br />
					<br />
					<div 
						style={{ 
							backgroundColor: "#f4e4cd", 
							padding: "20px", 
							marginTop: "20px",
							borderRadius: "5px"
						}}
					>
						<h5 className="text-center" style={{ marginBottom: "15px" }}>
							O ingresa con tu correo y clave
						</h5>
						<form onSubmit={handleEmailPasswordLogin}>
							<div className="grid-x grid-padding-x">
								<div className="cell">
									<input
										type="email"
										name="email"
										placeholder="Correo electrónico"
										value={credentials.email}
										onChange={handleInputChange}
										required
									/>
								</div>
								<div className="cell">
									<input
										type="password"
										name="password"
										placeholder="Contraseña"
										value={credentials.password}
										onChange={handleInputChange}
										required
									/>
								</div>
								<div className="cell">
									<button 
										type="submit" 
										className="button expanded"
										style={{
											backgroundColor: "#2e8ac7",
											color: "white"
										}}
									>
										Iniciar sesión
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
				<br />
			</div>
		</div>
	);
};

export default FormularioLogin;
